import * as React from "react";
// import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import InputBase from "@mui/material/InputBase";
// import Badge from "@mui/material/Badge";
// import MenuItem from "@mui/material/MenuItem";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import SearchIcon from "@mui/icons-material/Search";
// import AccountCircle from "@mui/icons-material/AccountCircle";
// import MailIcon from "@mui/icons-material/Mail";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import MoreIcon from "@mui/icons-material/MoreVert";
// import { useState } from "react";
// import { Divider, Drawer, Paper } from "@mui/material";
// import { Assessment, Create, HowToVote } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

// const Search = styled("div")(({ theme }) => ({
//     position: "relative",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     "&:hover": {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: "100%",
//     [theme.breakpoints.up("sm")]: {
//         marginLeft: theme.spacing(3),
//         width: "auto",
//     },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: "100%",
//     position: "absolute",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: "inherit",
//     "& .MuiInputBase-input": {
//         padding: theme.spacing(1, 1, 1, 0),
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create("width"),
//         width: "100%",
//         [theme.breakpoints.up("md")]: {
//             width: "20ch",
//         },
//     },
// }));

export default function Appbar() {
    // const [open, setOpen] = useState();
    // const [anchorEl, setAnchorEl] = useState(null);
    // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    // const links = [
    //     {
    //         text: "Create Poll",
    //         link: "/poll",
    //         Icon: <Create />,
    //     },
    //     {
    //         text: "Voting",
    //         link: "/voting",
    //         Icon: <HowToVote />,
    //     },
    //     {
    //         text: "Result",
    //         link: "/result",
    //         Icon: <Assessment />,
    //     },
    // ];

    // const isMenuOpen = Boolean(anchorEl);
    // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    // const handleProfileMenuOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleMobileMenuClose = () => {
    //     setMobileMoreAnchorEl(null);
    // };

    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    //     handleMobileMenuClose();
    // };

    // const handleMobileMenuOpen = (event) => {
    //     setMobileMoreAnchorEl(event.currentTarget);
    // };

    // const menuId = "primary-search-account-menu";
    // const renderMenu = (
    //     <Menu
    //         anchorEl={anchorEl}
    //         anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //         }}
    //         id={menuId}
    //         keepMounted
    //         transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //         }}
    //         open={isMenuOpen}
    //         onClose={handleMenuClose}
    //     >
    //         <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
    //         <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    //     </Menu>
    // );

    // const mobileMenuId = "primary-search-account-menu-mobile";
    // const renderMobileMenu = (
    //     <Menu
    //         anchorEl={mobileMoreAnchorEl}
    //         anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //         }}
    //         id={mobileMenuId}
    //         keepMounted
    //         transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //         }}
    //         open={isMobileMenuOpen}
    //         onClose={handleMobileMenuClose}
    //     >
    //         <MenuItem>
    //             <IconButton size="large" aria-label="new mails" color="inherit">
    //                 <Badge badgeContent={0} color="error">
    //                     <MailIcon />
    //                 </Badge>
    //             </IconButton>
    //             <p>Messages</p>
    //         </MenuItem>
    //         <MenuItem>
    //             <IconButton size="large" aria-label="new notifications" color="inherit">
    //                 <Badge badgeContent={0} color="error">
    //                     <NotificationsIcon />
    //                 </Badge>
    //             </IconButton>
    //             <p>Notifications</p>
    //         </MenuItem>
    //         <MenuItem onClick={handleProfileMenuOpen}>
    //             <IconButton
    //                 size="large"
    //                 aria-label="account of current user"
    //                 aria-controls="primary-search-account-menu"
    //                 aria-haspopup="true"
    //                 color="inherit"
    //             >
    //                 <AccountCircle />
    //             </IconButton>
    //             <p>Profile</p>
    //         </MenuItem>
    //     </Menu>
    // );

    return (
        <Box
            sx={{ flexGrow: 1 }}
            style={{
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                zIndex: 10,
            }}>
            <AppBar position="relative">
                <Toolbar>
                    {/*
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2, cursor: "pointer" }}
                        onClick={() => setOpen(!open)}
                    >
                        <MenuIcon />
                    </IconButton>
                    */}
                    <NavLink to="/">
                        <Typography variant="h6" sx={{ display: { xs: "none", sm: "block" } }} color="white">
                            Community Polls
                        </Typography>
                    </NavLink>
                    {/*}
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} />
                    </Search>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <IconButton size="large" aria-label="new mails" color="inherit">
                            <Badge badgeContent={0} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" aria-label="new notifications" color="inherit">
                            <Badge badgeContent={0} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                    */}
                </Toolbar>
            </AppBar>

            {/*}
            {renderMobileMenu}
            {renderMenu}

            <Drawer anchor="left" open={open} onClose={() => setOpen(!open)}>
                <Box sx={{ width: "300px", marginTop: "2rem" }}>
                    <Box mb={3}>
                        <NavLink to="/" onClick={() => setOpen(!open)}>
                            <Typography variant="h4" fontWeight="bold" textAlign="center">
                                Voting App
                            </Typography>
                        </NavLink>
                    </Box>
                    <Divider />
                    <Box>
                        {links?.map((val, ind) => {
                            return (
                                <Paper
                                    sx={{
                                        paddingY: "1.25rem",
                                        paddingLeft: "2rem",
                                    }}
                                    key={ind}
                                >
                                    <NavLink to={val?.link} style={{ color: "darkred" }} onClick={() => setOpen(!open)}>
                                        <Box display="flex" justifyContent="flex-left">
                                            <Box mr={2} display="flex" alignItems="center">
                                                {val?.Icon}
                                            </Box>
                                            <Typography variant="h6" fontWeight="bold">
                                                {val?.text}
                                            </Typography>
                                        </Box>
                                    </NavLink>
                                </Paper>
                            );
                        })}
                    </Box>
                </Box>
            </Drawer>
            */}
        </Box>
    );
}


import urlJoin from 'url-join'
import { toast } from "react-toastify";


export const shortAddress = function (address) {
    if (!address) {
        return ''
    }
    return address.slice(0, 6) + '...' + address.slice(-4);
};

export const shortHash = function (hash) {
    if (!hash) {
        return ''
    }
    return hash.slice(0, 6) + '...' + hash.slice(-4);
};

export const generateExplorerLink = function (blockExplorerUrl, address) {
    if (!address) {
        return '#';
    }
    return urlJoin(blockExplorerUrl, '/address', address);
};

export const generateHashLink = function (blockExplorerUrl, hash) {
    if (!hash) {
        return '#';
    }
    return urlJoin(blockExplorerUrl, '/tx', hash);
};

export const handleCopy = (address) => {
    navigator.clipboard.writeText(address).then(() => {
        toast.success('Address copied to clipboard!');
    }).catch((err) => {
        toast.error(`Failed to copy address: ${err.message}`);
    });
};


import { Box } from "@mui/material";
import React, {
    useState,
    useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    Link,
    IconButton,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from "react-toastify";

import {
    shortAddress,
    handleCopy,
    generateExplorerLink,
} from '../tools';
import pollsRequest from './pollRequest';
import {
    getPollStatusStyleByStatus,
} from './pollStatusConfigs';


const Home = () => {
    const navigate = useNavigate();
    const accessTokenKeyInUrl = 'skym_token'

    const [loading, setLoading] = useState(false)
    const [skymAccessToken, setSkymAccessToken] = useState(null)
    const [skymWeb3Auth] = useState(new window.SKYM.SKYMWeb3Auth(window.SKYM.SKYMWeb3AuthConfig))
    const [polls, setPolls] = useState([])

    function storeSkymAccessToken (token) {
        setSkymAccessToken(token)
        localStorage.setItem(window.SKYM.GATING_TOKEN_ACCESS_TOKEN_KEY, token)
    }

    function cleanSkymAccessToken () {
        setSkymAccessToken(null)
        localStorage.removeItem(window.SKYM.GATING_TOKEN_ACCESS_TOKEN_KEY)
    }

    function getSkymAccessToken () {
        return skymAccessToken || localStorage.getItem(window.SKYM.GATING_TOKEN_ACCESS_TOKEN_KEY) || null
    }

    function retrieveAccessTokenFromUrlOrStorage () {
        const params = new URLSearchParams(window.location.search)
        const skymAccessTokenFromUrl = ((params.get(accessTokenKeyInUrl) || '').trim()) || null
        if (skymAccessTokenFromUrl) {
            window.SKYM.removeQueryParam(accessTokenKeyInUrl)
            storeSkymAccessToken(skymAccessTokenFromUrl)
            return skymAccessTokenFromUrl
        }

        const skymAccessTokenFromStorage = getSkymAccessToken()
        if (skymAccessTokenFromStorage) {
            storeSkymAccessToken(skymAccessTokenFromStorage)
            return skymAccessTokenFromStorage
        }
    }

    async function handleUserHasLoggedInWhenOpenThePage () {
        const _ifUserIsLoggedIn = getSkymAccessToken() !== null

        if (_ifUserIsLoggedIn) {
            showLoading()
            try {
                await afterUserHasSignedIn()
            } catch (err) {
                console.error('[handleUserHasLoggedInWhenOpenThePage.failed]', err)
                showAuthFailureMessage(err.message)
            }
            hideLoading()
        }
    }

    async function signOutUser () {
        cleanSkymAccessToken()
        skymWeb3Auth.logout()
    }

    function showLoading () {
        setLoading(true)
    }

    function hideLoading () {
        setLoading(false)
    }

    function showAuthFailureMessage (msg) {
        toast.error(msg)
    }

    async function signInWithGENUN () {
        const currentUrl = window.location.href
        const newUrl = window.SKYM.GATING_TOKEN_GENUN_OAUTH2_URL + '?ort=' + encodeURIComponent(currentUrl)
        window.location.href = newUrl
    }

    async function signInWithWeb3Auth () {
        showLoading()
        try {
            await skymWeb3Auth.connect()
            await skymWeb3Auth.getUserInfo()
            const signature = await skymWeb3Auth.getSignature()

            const res = await window.axios({
                url: window.SKYM.GATING_TOKEN_AUTH_URL,
                method: 'post',
                data: {
                    ...signature,
                },
            })

            if (res?.data?.message) {
                showAuthFailureMessage(res.data.message)
            }
            if (res?.data?.skymToken) {
                storeSkymAccessToken(res.data.skymToken)
                await afterUserHasSignedIn()
            }
        } catch (err) {
            console.error('[signInWithMetamask.failed]', err)
            alert(err.message)
        }
        hideLoading()
    }

    async function signInWithMetamask () {
        showLoading()
        try {
            const skymMetamask = new window.SKYM.MetaMask()
            await skymMetamask.initMetaMask()

            const {
                network,
                nativeToken,
            } = window.SKYM.chains.ethereum

            await skymMetamask.switchChain({
                chainId: network.id,
                chainName: network.name,
                nativeCurrency: {
                    name: nativeToken.name,
                    symbol: nativeToken.symbol,
                    decimals: nativeToken.decimals,
                },
                rpcUrls: network.rpcUrl,
                blockExplorerUrls: network.blockExplorerUrl,
            })

            const signature = await skymMetamask.getSignature()

            const res = await window.axios({
                url: window.SKYM.GATING_TOKEN_AUTH_URL,
                method: 'post',
                data: {
                    ...signature,
                },
            })

            if (res?.data?.message) {
                showAuthFailureMessage(res.data.message)
            }
            if (res?.data?.skymToken) {
                storeSkymAccessToken(res.data.skymToken)
                await afterUserHasSignedIn()
            }
        } catch (err) {
            console.error('[signInWithMetamask.failed]', err)
            alert(err.message)
        }
        hideLoading()
    }

    async function afterUserHasSignedIn () {
        await loadPolls()
    }

    async function loadPolls () {
        const res = await pollsRequest({
            url: '/',
        })
        if (res.data.code !== 'Success') {
            if (res.data.code === 'InvalidToken') {
                signOutUser()
            } else {
                throw new Error(res.data.message)
            }
        }
        const _polls = res.data?.data?.data || []
        _polls.forEach((_poll) => {
            _poll._formatted_status = getPollStatusStyleByStatus(_poll.poll.status);
        })

        setPolls(_polls)
    }

    useEffect(() => {
        const _skymAccessToken = retrieveAccessTokenFromUrlOrStorage()
        if (_skymAccessToken) {
            handleUserHasLoggedInWhenOpenThePage()
        }
        return () => {
            // 清理代码，比如取消订阅等
            // 这个函数会在组件卸载前执行
        };
    }, [])

    return (
        <Box className="home" display="flex" width="100%" alignItems="center" justifyContent="center">
            {
                loading && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh"
                        style={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            zIndex: 9999,
                        }}>
                        <CircularProgress />
                    </Box>
                )
            }
            <Box className="wrapper">
            {
                !getSkymAccessToken()
                    ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            className="wrapper"
                            style={{
                                height: 'calc(100vh - 6rem)',
                            }}>
                            <Button
                                variant="contained"
                                style={{
                                    width: '236px',
                                    backgroundColor: '#f6851b',
                                    color: '#fff',
                                }} // Metamask 橙色
                                onClick={signInWithMetamask}
                            >
                                Continue with Metamask
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    marginTop: '10px',
                                    width: '236px',
                                    backgroundColor: '#3f51b5',
                                    color: '#fff',
                                }} // Web3Auth 蓝色
                                onClick={signInWithWeb3Auth}
                            >
                                Continue with Web3Auth
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    marginTop: '10px',
                                    width: '236px',
                                    backgroundColor: '#12a2a8',
                                    color: '#fff',
                                }} // GENUN 青色
                                onClick={signInWithGENUN}
                            >
                                Continue with GENUN
                            </Button>
                        </Box>
                    )
                    : (
                        <div>
                            {polls.map((poll, index) => (
                                <Card key={poll.poll.id} sx={{ margin: 2 }}>
                                    <CardContent>
                                        <Typography gutterBottom component="div" color="text.secondary">
                                            #{poll.poll.id}
                                        </Typography>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {poll.poll.name}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {poll.poll.proposal}
                                        </Typography>
                                        <Grid container spacing={2} sx={{ marginTop: 2 }}>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Typography variant="body2">
                                                    Address: &nbsp;<span style={{
                                                        color: '#333333',
                                                        textDecoration: 'none',
                                                    }}>
                                                        <Link
                                                            href={generateExplorerLink(poll.contract.network.blockExplorerUrl, poll.contract.address)}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {shortAddress(poll.contract.address)}
                                                        </Link>
                                                        <Tooltip title="Copy address">
                                                            <IconButton onClick={function () {
                                                                handleCopy(poll.contract.address);
                                                            }} size="small">
                                                                <FileCopyIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Typography variant="body2" color="text.primary">
                                                Chain: <span style={{
                                                    color: '#009BDB',
                                                }}>
                                                    {poll.contract.network.name}
                                                </span>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={3}>
                                                <Typography variant="body2">
                                                Status: <span style={{
                                                    color: poll._formatted_status.color,
                                                }}>
                                                    {poll._formatted_status.label}
                                                </span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} sx={{ justifyContent: 'flex-end' }} style={{
                                            marginTop: 0,
                                            lineHeight: '1.75rem',
                                            height: '3.75rem',
                                        }}>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={function () {
                                                        navigate(`/poll/${poll.poll.id}`);
                                                    }}>
                                                    View
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                    )
            }
            </Box>
        </Box>
    );
};

export default Home;

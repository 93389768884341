
const pollsBaseURL = window.SKYM.GATING_TOKEN_AUTH_DOMAIN + '/api/v1/gating-token/polls'
const pollsRequest = window.axios.create({
    baseURL: pollsBaseURL,
    timeout: 1000 * 60 * 5,
})
pollsRequest.interceptors.request.use(config => {
    const token = localStorage.getItem(window.SKYM.GATING_TOKEN_ACCESS_TOKEN_KEY);
    if (token) {
        config.headers['skym_token'] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
})


export default pollsRequest

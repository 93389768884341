
export const pollStatusConfigs = {
    0: {
        color: '#808080',
        label: 'Not Started',
    },
    1: {
        color: '#007BFF',
        label: 'In Progress',
    },
    2: {
        color: '#DC3545',
        label: 'Ended',
    },
    unknown: {
        color: '#FFC107',
        label: 'Unknown',
    },
}

export const getPollStatusStyleByStatus = (status) => {
    return pollStatusConfigs[status] || pollStatusConfigs.unknown;
}

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    CircularProgress,
    Box,
    Paper,
    Grid,
    Link,
    IconButton,
    Tooltip,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TablePagination,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { toast } from "react-toastify";
import cloneDeep from 'lodash.clonedeep';

import {
    shortAddress,
    shortHash,
    handleCopy,
    generateExplorerLink,
    generateHashLink,
} from '../tools';
import pollsRequest from './pollRequest';
import {
    getPollStatusStyleByStatus,
} from './pollStatusConfigs';


function PollDetail() {
    const { id } = useParams();
    const [selectedChoice, setSelectedChoice] = useState('');
    const [poll, setPoll] = useState(null);
    const [loading, setLoading] = useState(true);
    const [userIfVoted, setUserIfVoted] = useState({});
    const [totalVotes, setTotalVotes] = useState(0);
    const [pollSummary, setPollSummary] = useState([]);
    const [pollTransactions, setPollTransactions] = useState([]);
    const [pollTransactionCount, setPollTransactionCount] = useState(0);
    const [pollTransactionLimit, setPollTransactionLimit] = useState(10);
    const [pollTransactionPage, setPollTransactionPage] = useState(0);


    useEffect(() => {
        const loadAccount = async () => {
            try {
                const _poll = await pollsRequest({
                    url: `/${ id }`,
                }).then((res) => {
                    return res.data.data
                })
                _poll.statistics.choices.sort((a, b) => { return b.votes - a.votes; });
                _poll._formatted_status = getPollStatusStyleByStatus(_poll.poll.status);
                setPoll(_poll);
                setTotalVotes(_poll.statistics.totalVotes);
                setPollSummary(_poll.statistics.choices);

                const ifVoted = await pollsRequest({
                    url: `/${ id }/ifVoted`,
                }).then((res) => {
                    return res.data.data
                });
                setUserIfVoted(ifVoted);
                if (ifVoted.ifVoted) {
                    setSelectedChoice(ifVoted.choices[0].pollChoiceUintId)
                }

                const _pollTransactions = await pollsRequest({
                    url: `/${ id }/votes`,
                }).then((res) => {
                    return res.data.data
                });
                setPollTransactionCount(_pollTransactions.total)
                setPollTransactions(_pollTransactions.data)
            } catch (error) {
                console.error('Failed to load poll data:', error);
                toast.error(`Failed to load poll data: ${error.message}`);
            }

            setLoading(false);
        }

        loadAccount();
    }, [id]);

    const handlePollTransactionPagination = () => {
        console.log('handlePollTransactionPagination.pollTransactionLimit', pollTransactionLimit)
        console.log('handlePollTransactionPagination.pollTransactionPage', pollTransactionPage)
    }


    const handleVoteChange = (event) => {
        setSelectedChoice(event.target.value);
    };

    const performVote = async () => {
        try {
            if (userIfVoted.ifVoted) {
                return;
            }
            setLoading(true);
            const voted = await pollsRequest({
                url: `/${ id }/vote`,
                method: 'post',
                data: {
                    uintId: selectedChoice,
                },
            }).then((res) => {
                return res.data.data
            });
            setUserIfVoted(voted);
            const _pollSummary = cloneDeep(pollSummary).map((item) => {
                if (item.uintId.toString() === selectedChoice.toString()) {
                    item.votes += 1;
                }
                return item;
            })
            setPollSummary([..._pollSummary]);
            setTotalVotes(totalVotes + 1);
            setPollTransactions([...pollTransactions, {
                vote: {
                    id: voted.vote.id,
                    label: 'You',
                    voter: voted.vote.voter,
                    txHash: voted.vote.txHash,
                },
                choices: voted.choices,
            }]);
            setPollTransactionCount(pollTransactionCount + 1);
            toast.success("Vote casted successfully.");
        } catch (error) {
            console.error('@performVote.error', error)
            toast.error(`Failed to cast vote: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    if (loading && !poll) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!poll) {
        return (
            <Typography variant="h5" textAlign="center">Poll not found</Typography>
        );
    }

    return (
        <Box
            display="flex"
            width="100%"
            minHeight="100vh"
            alignItems="center"
            justifyContent="center">
            {
                loading && poll && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh"
                        style={{
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            zIndex: 10000,
                        }} >
                        <CircularProgress />
                    </Box>
                )
            }
                <div
                    style={{
                        width: '100%',
                        paddingInline: '2rem',
                        paddingInlineBottom: '0',
                    }}>
                    <Card key={poll.poll.id} sx={{ margin: 2 }}>
                        <CardContent>
                            <Typography gutterBottom component="div" color="text.secondary">
                                #{poll.poll.id}
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div">
                                {poll.poll.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {poll.poll.proposal}
                            </Typography>
                            <Grid container spacing={2} sx={{ marginTop: 2 }}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="body2">
                                        Address: &nbsp;<span style={{
                                            color: '#333333',
                                            textDecoration: 'none',
                                        }}>
                                            <Link
                                                href={generateExplorerLink(poll.contract.network.blockExplorerUrl, poll.contract.address)}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {shortAddress(poll.contract.address)}
                                            </Link>
                                            <Tooltip title="Copy address">
                                                <IconButton onClick={function () {
                                                    handleCopy(poll.contract.address);
                                                }} size="small">
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="body2" color="text.primary">
                                    Chain: <span style={{
                                        color: '#009BDB',
                                    }}>
                                        {poll.contract.network.name}
                                    </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography variant="body2">
                                    Status: <span style={{
                                        color: poll._formatted_status.color,
                                    }}>
                                        {poll._formatted_status.label}
                                    </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Grid key={`${ poll.poll.id }-vote`} sx={{ margin: 2 }}>
                        <Grid item xs={12} md={8} lg={6}>
                            <Card>
                                <CardContent>
                                    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                        <Typography variant="h6">Choices</Typography>
                                        <RadioGroup name="voteChoices" value={selectedChoice} onChange={handleVoteChange}>
                                            {poll.choices.map((choice, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    value={choice.uintId}
                                                    control={<Radio />}
                                                    label={choice.title}
                                                    disabled={userIfVoted.ifVoted || !userIfVoted.canVote || poll.poll.status !== 1}
                                                />
                                            ))}
                                        </RadioGroup>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={performVote}
                                            disabled={poll.poll.status !== 1 || userIfVoted.ifVoted || !userIfVoted.canVote || !selectedChoice}>
                                            Submit Choice
                                        </Button>
                                        {
                                            poll.poll.status === 0 && (
                                                <Typography color="secondary" sx={{ mt: 2 }}>
                                                    Poll hasn't started yet.
                                                </Typography>
                                            )
                                        }
                                        {
                                            poll.poll.status === 2 && (
                                                <Typography color="secondary" sx={{ mt: 2 }}>
                                                    Poll has ended.
                                                </Typography>
                                            )
                                        }
                                        {
                                            ![0, 1, 2].includes(poll.poll.status) && (
                                                <Typography color="secondary" sx={{ mt: 2 }}>
                                                    Invalid poll status.
                                                </Typography>
                                            )
                                        }
                                        {
                                            !userIfVoted.canVote && (
                                                <Typography color="secondary" sx={{ mt: 2 }}>
                                                    You do not have the required NFT to vote.
                                                </Typography>
                                            )
                                        }
                                        {userIfVoted.ifVoted && (
                                            <Typography color="secondary" sx={{ mt: 2 }}>
                                                You have already voted for: {userIfVoted.choices.map((item) => { return item.title }).join(', ')}
                                            </Typography>
                                        )}
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Grid key={`${ poll.poll.id }-result`} sx={{ margin: 2 }}>
                        <Grid item xs={12} md={8} lg={6}>
                            <Card>
                                <CardContent>
                                    <Paper elevation={1} sx={{ p: 2, mb: 2 }}>
                                        <Typography variant="h6">Summary</Typography>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    <TableRow
                                                        key={'header'}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            CHOICE
                                                        </TableCell>
                                                        <TableCell align="right">Count</TableCell>
                                                    </TableRow>
                                                    {pollSummary.map(({ choice, votes, uintId }) => (
                                                        <TableRow
                                                        key={uintId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {choice}
                                                            </TableCell>
                                                            <TableCell align="right">{votes}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Typography
                                            align='right'
                                            style={{
                                                marginRight: '1rem',
                                                marginTop: '1rem',
                                            }}>
                                            Total: {totalVotes}
                                        </Typography>
                                    </Paper>
                                    <Paper elevation={1} sx={{ p: 2 }}>
                                        <Typography variant="h6">Transactions</Typography>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            width: '160px',
                                                        }}>Transaction Hash</TableCell>
                                                    <TableCell
                                                        align="right"
                                                        style={{
                                                            width: '160px',
                                                        }}>Voter</TableCell>
                                                    <TableCell>Choice</TableCell>
                                                </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {pollTransactions.map((row) => (
                                                    <TableRow
                                                    key={row.vote.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                    <TableCell scope="row">
                                                        <Link
                                                            href={generateHashLink(poll.contract.network.blockExplorerUrl, row.vote.txHash)}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            {shortHash(row.vote.txHash)}
                                                        </Link>
                                                    </TableCell>
                                                    <TableCell align="right">{shortAddress(row.vote.voter)}</TableCell>
                                                    <TableCell>{
                                                        row.choices.map((item) => item.title).join(', ')
                                                    }</TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                            component="div"
                                            count={pollTransactionCount} // 总行数
                                            rowsPerPage={pollTransactionLimit} // 每页行数
                                            page={pollTransactionPage} // 当前页码
                                            onPageChange={handlePollTransactionPagination}
                                            onRowsPerPageChange={handlePollTransactionPagination}
                                            />
                                    </Paper>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
        </Box>
    );
}

export default PollDetail;
